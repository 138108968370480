import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <h1>VaccineVA</h1>
      <p>Coming soon!</p>
    </main>
  )
}

export default IndexPage
